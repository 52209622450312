(function() {
  var realCodePathAIChatbot = function(settings) {
      // Initialization code for each instance here...
      initializeBot(settings);
  };

  var queuedCommands = window.CodePathAIChatbot.q;
  if (queuedCommands) {
      queuedCommands.forEach(function(args) {
          realCodePathAIChatbot.apply(null, args);
      });
  }

  window.CodePathAIChatbot = realCodePathAIChatbot;
})();

function initializeBot(settings) {
  const botId = settings.botId;
  const jwt = settings.jwt;
  const inlineDivId = settings.inlineDivId;
  const initialMessage = settings.initialMessage || "";
  const title = settings.title || "";

  // Check if any of the variables are falsy
  if (!botId || (botId == 'undefined') || !jwt || (jwt == 'undefined') || !inlineDivId || (inlineDivId == 'undefined')) {
    console.error("Error: One or more settings are missing or falsy.");
    return; // Exit the function
  }

  const inlineDivElement = document.getElementById(inlineDivId);

  if (inlineDivElement) {
    const iframe = document.createElement('iframe');
    iframe.src = `${process.env.DEPLOYED_URL}/index.html?initialMessage=${encodeURIComponent(initialMessage)}&jwt=${encodeURIComponent(jwt)}&botId=${encodeURIComponent(botId)}&title=${encodeURIComponent(title)}`;
    iframe.style.border = "none";
    iframe.style.height = "100%";
    iframe.style.width = "100%";
    iframe.allow = "clipboard-write"

    inlineDivElement.appendChild(iframe);
  }
}